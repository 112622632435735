<template>
  <div class="item-form col-md-12 mt-4">
    <form>
      <div class="d-flex justify-content-between">
        <div class="form-group col-md-3">
          <label for="itemName">Canteen Name:</label>
          <!-- <input type="text" id="itemName" v-model="canteenName" required />
         -->
          <select id="CanteenName" v-model="canteenName" required>
            <option value="">-- Select canteen --</option>
            <option
              v-for="canteen in canteenlist"
              :key="canteen.id"
              :value="canteen.name"
            >
              {{ canteen.name }}
            </option>
          </select>
        </div>

        <div class="form-group col-md-3">
          <label for="Shopkeeper">Shopkeeper:</label>
          <select id="Shopkeeper" v-model="shopkeeper" required>
            <option
              v-for="shopkeeper in userlist"
              :key="shopkeeper.id"
              :value="shopkeeper.userName"
            >
              {{ shopkeeper.userName }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="price">Date:</label>
          <input type="date" id="price" v-model="date" required />
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="form-group col-md-3">
          <label for="status">Status:</label>
          <select id="status" v-model="status" required>
            <option value="New" >New</option>
            <option value="Received">Received</option>
            <option value="Completed">Completed</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="deliverydate">Delivery Date:</label>
          <input type="date" id="deliverydate" v-model="deliverydate" required />
        </div>
        <div class="form-group col-md-3">
        </div>
      </div>
      
      
      <div class="tableminheight">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Unit</th>
              <th>Quantity(Unit)</th>
              <th>Alternative Unit</th>
              <th>Quantity(Alternative Unit)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="products in productsData"
              :key="products.itemId"
            >
              <td>{{ products.itemName }}</td>
              <td>{{ products.unit }}</td>
              <td>{{ products.itemCount }}</td>
              <td>{{ products.altUnit }}</td>
              <td>{{ products.altItemCount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-between"></div>
      <div class="d-flex justify-content-end mt-4">
       
        <button
          type="button"
          class="btn btn-primary buttonclass"
          @click.prevent="$router.push(`/Orderlist`)"
        >
          OK
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    orderId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      deliverydate:null,
      itemCount:null,
      altItemCount:null,
      itemnamestr: "",
      orderCode: "",
      itemname: "",
      noofitems: "",
      expdatedate: null,
      addProductsInOrderpopup: false,
      id: "",
      canteenName: "",
      productData: "",
      status: "new",
      date: this.getCurrentDate(),
      shopkeeper: "",
      canteenlist: [],
      Itemlist: [],
      productsData: [],
      userlist: [],
      unit: "",
      ratePerUnit: "",
      alternativeUnit: "",
      convertionRate: "",
    };
  },
  mounted() {
    console.log("this.itemId", this.itemId);
    if (this.itemId != "0") {
      this.getorders();
    }
    this.getcanteene();
    this.getItemlist();
    this.getuserlist();
  },
  methods: {
    onitemChange() {
      const selectedItemName = this.itemname;
      console.log("this.itemname", this.itemname);
      console.log("this.Itemlist", this.Itemlist);
      const selectedItemObject = this.Itemlist.find(
        (item) => item.id === selectedItemName
      );
      console.log("selectedItemObject", selectedItemObject);
      this.itemnamestr = selectedItemObject.itemName;
      this.unit = selectedItemObject.unit;
      this.ratePerUnit = selectedItemObject.rate;
      this.alternativeUnit = selectedItemObject.alternativeUnit;
      this.convertionRate = selectedItemObject.conversionRate;
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    deleteitem(index) {
      if (index > -1) {
        this.productsData.splice(index, 1);
      }
    },
    async getuserlist() {
      await axios
        .get(
          `https://dmsdev.datamatica.uk/v1/document/test/getalluser?role=Shopkeeper`
        )
        .then((res) => {
          this.userlist = res.data;
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    getItemName(itemId) {
      const item = this.Itemlist.find((item) => item.id === itemId);
      return item ? item.itemName : "Unknown Item";
    },
    async addproducts(itemid) {
      const proddata = {
        itemId: this.itemname,
        itemName: this.itemnamestr,
        itemCount: this.itemCount,
        actualItemCount: 0,
        unit: this.unit,
        altItemCount: this.altItemCount,
        altUnit: this.alternativeUnit,
        expireDate: this.expdatedate,
      };
      this.productsData.push(proddata);
      this.itemName = "";
      this.noofitems = "";
      this.itemnamestr= "";
      this.itemCount=null;
      this.unit="";
      this.altItemCount=null;
      this.alternativeUnit="";
      this.expdatedate=null;
      this.expdatedate = null;
    },
    async getItemlist() {
      await axios
        .get(`https://dmsdev.datamatica.uk/v1/document/test/getallitems`)
        .then((res) => {
          this.Itemlist = res.data;
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    async getcanteene() {
      await axios
        .get(`https://dmsdev.datamatica.uk/v1/document/test/listcanteens`)
        .then((res) => {
          this.canteenlist = res.data;
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    async submitForm() {
      if (this.orderId != "0") {
        this.edititem();
      } else {
        this.saveitem();
      }
    },
    async edititem() {
      const itemDetails = {
        id: this.id,
        canteenName: this.canteenName,
        orderCode: this.orderCode,
        productsData: this.productsData,
        status: this.status,
        date: this.date,
        shopkeeper: this.shopkeeper,
      };
      await axios
        .put(
          `https://dmsdev.datamatica.uk/v1/document/test/updateorder?id=${this.orderId}`,
          itemDetails
        )
        .then((res) => {
          console.log(res.data);
          this.onclosed();
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
      console.log(itemDetails);
      // You can add more logic here, such as sending the data to an API
    },
    async saveitem() {
      const itemDetails = {
        id: this.id,
        canteenName: this.canteenName,
        orderCode: this.orderCode,
        productsData: this.productsData,
        status: this.status,
        date: this.date,
        shopkeeper: this.shopkeeper,
      };
      await axios
        .post(
          `https://dmsdev.datamatica.uk/v1/document/test/createorder`,
          itemDetails
        )
        .then((res) => {
          console.log(res.data);
          this.onclosed();
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
      console.log(itemDetails);
      // You can add more logic here, such as sending the data to an API
    },
    async formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0 to 11
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
},
    async getorders() {
      await axios
        .get(
          `https://dmsdev.datamatica.uk/v1/document/test/getorderbyid?id=${this.orderId}`
        )
        .then((res) => {
          console.log(res.data);
          this.id = res.data.id;
          this.canteenName = res.data.canteenName;
          this.productsData = res.data.productsData;
          this.status = res.data.status;
          this.date = res.data.date.split("T")[0];
          this.shopkeeper = res.data.shopkeeper;
          this.deliverydate= res.data.deliveryDate.split("T")[0];
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
      // You can add more logic here, such as sending the data to an API
    },
    onclosed() {
      this.$router.push(`/Orderlist`);
    },
  },
};
</script>

<style scoped>
.maxheightelem {
  height: 40px;
}
.tableminheight {
  min-height: 150px;
  max-height: 350px;
  overflow: auto;
}
.buttonclass {
  width: 100px;
  margin-left: 10px;
}
.item-form {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 15px;
}
.divider-with-header {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.divider-with-header h5 {
  margin: 0;
  padding-right: 10px;
}
.divider-with-header hr {
  flex-grow: 1;
  border: none;
  border-top: 2px solid #333; /* Darker line */
}
.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}
</style>
