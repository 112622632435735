<template>
    <div class="container">
      <h2 class="headding">Low Stock Alert</h2>
      <div class="containers mt-5">
        <table class="table table-bordered">
  <thead>
    <tr>
      <th>Item Code</th>
      <th>Item Name</th>
      <th>Company</th>
      <th>Last Purchase</th>
      <th>Price</th>
      <th>Quantity</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>001</td>
      <td>Laptop</td>
      <td>ABC Electronics</td>
      <td>2022-05-10</td>
      <td>$999</td>
      <td>10</td>
      <td><button>Edit</button></td>
    </tr>
    <tr>
      <td>002</td>
      <td>Smartphone</td>
      <td>XYZ Mobiles</td>
      <td>2022-05-12</td>
      <td>$699</td>
      <td>20</td>
      <td><button>Edit</button></td>
    </tr>
    <tr>
      <td>003</td>
      <td>Printer</td>
      <td>DEF Office Supplies</td>
      <td>2022-05-15</td>
      <td>$299</td>
      <td>5</td>
      <td><button>Edit</button></td>
    </tr>
    <tr>
      <td>004</td>
      <td>Monitor</td>
      <td>GHI Technologies</td>
      <td>2022-05-18</td>
      <td>$399</td>
      <td>15</td>
      <td><button>Edit</button></td>
    </tr>
  </tbody>
</table>
</div>
    </div>
  </template>
  
  <script>
  import store from "../../store/index";
  export default {
    data() {
      return {
        username: '',
        password: ''
      };
    },
    methods: {
      login() {
        console.log('Username:', this.username);
        console.log('Password:', this.password);
        localStorage.setItem('loggedIn', "true");
        store.dispatch("setloggedIn", "true");
        this.$router.push(`/home`);
      }
    }
  };
  </script>
  <style>
  .container{
    border: 2px solid #000; /* Black border */
  border-radius: 10px; /* Curved edges */
  border: 2px solid #000;
    border-radius: 10px;
    margin-top: 30px;
    min-height: 100%;
  }
  .headding{
    margin-left: 10px;
  }
  .table th {
      background-color: #f0f0f0; /* Gray background for header */
    }
    .table-bordered {
      border: none; /* Remove border */
    }
  </style>