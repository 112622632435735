import store from "../store/index";
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../components/Dashboard/DashboardPage.vue'
import LoginForm from '../components/LoginPage/LoginForm.vue'
import lowstockalert from '../components/LowStockAlert/LowStockAlert.vue'
import AllStock from '../components/AllStock/AllStock.vue'
import AvailableStock from '../components/AvailableStock/AvailableStock.vue'
import UsersPage from '../components/Users/UsersPage.vue'
import ItemPage from '../components/Items/ItemsList.vue'
import CreateItem from '../components/CreateItem/CreateItem.vue'
import CreateStock from '../components/CreateStock/CreateStock.vue'
import category from '../components/Category/categoryList.vue'
import supplylist from '../components/SupplierList/SupplierList.vue'
import caneenlist from '../components/canteenList/canteenList.vue'
import Orderlist from '../components/OrderList/OrderList.vue'
import OrderCreate from "@/components/OrderCreate/OrderCreate.vue";
import OrderView from '../components/OrderView/OrderView.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'LoginForm',
    component: LoginForm
  },
  {
    path: '/lowstockalert',
    name: 'lowstockalert',
    component: lowstockalert
  },
  {
    path: '/canteenlist',
    name: 'canteenlist',
    component: caneenlist
  },
  {
    path: '/category',
    name: 'category',
    component: category
  },
  {
    path: '/supplylist',
    name: 'supplylist',
    component: supplylist
  },
  {
    path: '/ItemPage',
    name: 'ItemPage',
    component: ItemPage
  },
  {
    path: '/CreateItem/:itemId',
    name: 'CreateItem',
    props: true,
    component: CreateItem
  },
  {
    path: '/Orderlist',
    name: 'Orderlist',
    component: Orderlist
  },
  {
    path: '/OrderCreate/:orderId',
    name: 'OrderCreate',
    props: true,
    component: OrderCreate
  },
  {
    path: '/OrderView/:orderId',
    name: 'OrderView',
    props: true,
    component: OrderView
  },
  {
    path: '/AllStock',
    name: 'AllStock',
    component: AllStock
  },
  {
    path: '/Createstock/:stockId',
    name: 'Stocklist',
    props: true,
    component: CreateStock
  },
  {
    path: '/AvailableStock',
    name: 'AvailableStock',
    component: AvailableStock
  },
  {
    path: '/Users',
    name: 'UsersPage',
    component: UsersPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(async (to, from, next) => {
  const isloggedin = store.getters.getifloggedIn == "true";

  if (isloggedin && to.fullPath === "/") {
    return next({ path: "/home" });
  } else if (!isloggedin && to.fullPath !== "/") {
    return next({ path: "/" });
  } 
  else{
  return next();
}
});
export default router
