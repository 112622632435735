<template>
  <div id="app">
    <MainPage></MainPage>
  </div>
</template>

<script>
import MainPage from './common/MainPage/MainPage.vue';
export default {
  components: { MainPage },
  data() {
    return {
      loggedIn: false
    };
  },
  created() {
    this.loggedIn = localStorage.getItem('loggedIn') === 'true';
  }
};
</script>

<style>
#app {
  font-family: Arial, sans-serif;
}
</style>