<template>
  <div class="modal__upload__wrapper">
    <div class="modal__upload__container">
      <div
        class="modal__upload__title d-flex justify-content-between py-2 px-3 text-dark"
      >
        <h4 class="my-0">Create User</h4>
        <div class="cursor-pointer" @click="onclosed()">
          <font-awesome-icon icon="times-circle" class="iconwhite" />
        </div>
      </div>
      <div
        class="modal__upload__content min-height-230 d-flex flex-column justify-content-between py-4 px-5 containnersub"
      >
        <form @submit.prevent="createUser">
          <div class="form-group">
            <label for="userName">User Name</label>
            <input
              type="text"
              v-model="user.userName"
              id="userName"
              class="form-control"
              required
            />
          </div>
          <div class="form-group">
            <label for="userName">Name</label>
            <input
              type="text"
              v-model="user.fullName"
              id="fullName"
              class="form-control"
              required
            />
          </div>
          <div class="form-group">
            <label for="userEmail">Email</label>
            <input
              type="email"
              v-model="user.email"
              id="userEmail"
              class="form-control"
              required
            />
          </div>
          <div class="form-group">
            <label for="userPassword">Password</label>
            <input
              type="password"
              v-model="user.password"
              id="userPassword"
              class="form-control"
              required
            />
          </div>
          <div class="form-group">
            <label for="userRole">Role</label>
            <select
              v-model="user.roles[0]"
              id="userRole"
              class="form-control"
            >
              <option value="">-- Enter Role --</option>
              <option v-for="role in roles" :key="role" :value="role">{{ role }}</option>
            </select>
          </div>
          <div v-if="user.roles[0] == 'Canteen User'" class="form-group">
            <label for="userRole">Canteen</label>
            <select
              v-model="user.canteenName"
              id="userRole"
              class="form-control"
              multiple
            >
              <option value="">-- Select Canteen --</option>
              <option
                v-for="canteen in canteenlist"
                :key="canteen.id"
                :value="canteen.name"
              >
                {{ canteen.name }}
              </option>
            </select>
          </div>
          <button
            type="submit"
            class="btn btn-primary mt-3"
            @click.prevent="submitfun()"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
export default {
  name: "Createuserpopup",
  data() {
    return {
      user: {
        id: "",
        fullName: "",
        userName: "",
        email: "",
        password: "",
        roles: [""],
        canteenName: [],
      },
      canteenlist: [],
      roles: ["Admin", "User", "Canteen User", "Shopkeeper"],
    };
  },
  props: {
    currentuserid: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.getcanteene();
    this.getuser(this.currentuserid);
  },
  computed: {
    selectedRole: {
      get() {
        return this.user.roles[0];
      },
      set(value) {
        this.user.roles.splice(0, 1, value);
      },
    },
  },
  methods: {
    async getcanteene() {
      await axios
        .get(`https://dmsdev.datamatica.uk/v1/document/test/listcanteens`)
        .then((res) => {
          this.canteenlist = res.data;
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    async getuser(uid) {
      await axios
        .get(
          `https://dmsdev.datamatica.uk/v1/document/test/getuser?userId=${uid}`
        )
        .then((res) => {
          this.user = res.data;
          console.log(res.data);
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    async onclosed() {
      this.$emit("oncloseevent");
    },
    async submitfun() {
      if (this.user.id == "") {
        this.createfun();
      } else {
        this.editfun();
      }
    },
    async editfun() {
      await axios
        .put(
          `https://dmsdev.datamatica.uk/v1/document/test/updateuser`,
          this.user
        )
        .then((res) => {
          console.log(res.data);
          this.onclosed();
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    async createfun() {
      await axios
        .post(
          `https://dmsdev.datamatica.uk/v1/document/test/createuser`,
          this.user
        )
        .then((res) => {
          console.log(res.data);
          this.onclosed();
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
@import "../../assets/css/style.css";
.modal__upload__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3000;
}
.modal__upload__container {
  width: min(38%, 80%);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
}
.modal__upload__title {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #ffffff;
}
.height-230 {
  height: 230px;
}
.upload_btn {
  text-align: center;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;
  padding: 0.43rem 0.4rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: all 0.3s ease-in-out !important;
}
.upload_btn img {
  mix-blend-mode: color-dodge;
}
.cursor-pointer {
  cursor: pointer;
}
.containnersub{
  height:500px;
  overflow: auto;
}
</style>
