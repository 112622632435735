<template>
  <div class="item-form col-md-12 mt-4">
    <form>
      <div class="d-flex justify-content-between">
        <div class="form-group col-md-3">
          <label for="canteenName">Canteen Name<sup><font-awesome-icon icon="asterisk" class="text-danger"/></sup>:</label>
          <select id="canteenName" v-model="canteenName" @blur="validateField('canteenName')" required>
            <option value="">-- Select canteen --</option>
            <option
              v-for="canteen in canteenlist"
              :key="canteen.id"
              :value="canteen.name"
            >
              {{ canteen.name }}
            </option>
          </select>
          <span v-if="errors.canteenName" class="text-danger">{{ errors.canteenName }}</span>
        </div>

        <div class="form-group col-md-3">
          <label for="shopkeeper">Shopkeeper:</label>
          <select id="shopkeeper" v-model="shopkeeper" required>
            <option
              v-for="shopkeeper in userlist"
              :key="shopkeeper.id"
              :value="shopkeeper.userName"
            >
              {{ shopkeeper.userName }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="price">Order Date:</label>
          <input type="date" id="price" v-model="date" required />
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="form-group col-md-3">
          <label for="status">Status:</label>
          <select id="status" v-model="status" required>
            <option value="New">New</option>
            <option value="Received">Received</option>
            <option value="Completed">Completed</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="deliverydate">Delivery Date:</label>
          <input type="date" id="deliverydate" v-model="deliverydate" required />
        </div>
        <div class="form-group col-md-3"></div>
      </div>

      <div class="d-flex justify-content-between">
        <div class="form-group col-md-5"></div>
      </div>

      <!-- Divider with header -->
      <div class="divider-with-header">
        <h5>Add Items</h5>
        <hr />
      </div>
      <div class="d-flex justify-content-between">
        <div class="form-group col-md-4">
          <label for="itemname">Item Name<sup><font-awesome-icon icon="asterisk" class="text-danger"/></sup>:</label>
          <select
            id="itemname"
            class="maxheightelem"
            v-model="itemname"
            @change="onitemChange()"
          >
            <option v-for="items in Itemlist" :key="items.id" :value="items.id">
              {{ items.itemName }}
            </option>
          </select>
          <span v-if="errors.itemname" class="text-danger">{{ errors.itemname }}</span>
        </div>

        <div class="form-group col-md-4"></div>
        <div class="form-group col-md-2"></div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="form-group col-md-4">
          <label for="unit">Unit:</label>
          <select id="unit" v-model="unit" disabled class="custom-disabled">
            <option value="CTN">CTN</option>
            <option value="BDL">BDL</option>
            <option value="CS">CS</option>
            <option value="CAN">CAN</option>
            <option value="PC">PC</option>
          </select>
        </div>

        <div class="form-group col-md-4">
          <label for="alternativeUnit">Quantity<sup><font-awesome-icon icon="asterisk" class="text-danger"/></sup>:</label>
          <input
            type="number"
            class="maxheightelem"
            id="noofitems"
            v-model="itemCount"
          />
          <span v-if="errors.itemCount" class="text-danger">{{ errors.itemCount }}</span>
        </div>

        <div class="form-group col-md-2 d-flex align-items-end"></div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="form-group col-md-4">
          <label for="alternativeUnit" v-if="convertionRate">
            Alternative Unit (1 {{ unit }} = {{ convertionRate }}
            {{ alternativeUnit }}):
          </label>
          <label for="alternativeUnit" v-else>Alternative Unit :</label>
          <select
            id="alternativeUnit"
            v-model="alternativeUnit"
            disabled
            class="custom-disabled"
          >
            <option value="CTN">CTN</option>
            <option value="BDL">BDL</option>
            <option value="CS">CS</option>
            <option value="CAN">CAN</option>
            <option value="PC">PC</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label for="unit">Quantity:</label>
          <input
            type="number"
            class="maxheightelem"
            id="noofitems"
            v-model="altItemCount"
          />
        </div>
        <div class="form-group col-md-2 d-flex align-items-end">
          <button
            class="buttonclass btn btn-primary mr-2 btn-sm w-100"
            type="button"
            @click="addproducts"
          >
            Add Items
          </button>
        </div>
      </div>
      <div class="tableminheight">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Unit</th>
              <th>Quantity(Unit)</th>
              <th>Alternative Unit</th>
              <th>Quantity(Alternative Unit)</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(products, index) in productsData"
              :key="products.itemId"
            >
              <td>{{ products.itemName }}</td>
              <td>{{ products.unit }}</td>
              <td>
                <span v-if="itemeditobj != index">{{ products.itemCount }}</span>
                <span v-else>
                  <input
                    type="number"
                    v-model="products.itemCount"
                    required
                  />
                </span>
              </td>
              <td>{{ products.altUnit }}</td>
              <td>
                <span v-if="itemeditobj != index">{{ products.altItemCount }}</span>
                <span v-else>
                  <input
                    type="number"
                    v-model="products.altItemCount"
                    required
                  />
                </span>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-info col-md-12"
                  @click.prevent="editlistitem(index)"
                >
                  Edit
                </button>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-danger col-md-12"
                  @click.prevent="deleteitem(index)"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-between"></div>
      <div class="d-flex justify-content-end mt-4">
        <button
          class="buttonclass btn btn-primary mr-2"
          type="submit"
          @click.prevent="handleSubmit()"
        >
          Submit
        </button>
        <button
          type="button"
          class="btn btn-danger buttonclass"
          @click.prevent="$router.push(`/Orderlist`)"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import store from "@/store";
import axios from "axios";
export default {
  props: {
    orderId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      deliverydate: null,
      itemCount: null,
      altItemCount: null,
      itemnamestr: "",
      orderCode: "",
      itemname: "",
      noofitems: "",
      expdatedate: null,
      addProductsInOrderpopup: false,
      id: "",
      canteenName: "",
      productData: "",
      status: "New",
      date: this.getCurrentDate(),
      shopkeeper: "",
      canteenlist: [],
      Itemlist: [],
      productsData: [],
      userlist: [],
      unit: "",
      ratePerUnit: "",
      alternativeUnit: "",
      convertionRate: "",
      userid: "",
      itemeditobj: null,
      errors: {
        canteenName: null,
        itemname: null,
        itemCount: null,
      },
    };
  },
  mounted() {
    this.userid = store.getters.getusername;
    console.log("this.itemId", this.itemId);
    if (this.itemId != "0") {
      this.getorders();
    }
    this.getcanteene();
    this.getItemlist();
    this.getuserlist();
  },
  methods: {
    handleSubmit() {
      if (this.isFormValid()) {
        this.submitForm();
      }
    },
    isFormValid() {
      this.errors = {
        canteenName: !this.canteenName ? "Please select a canteen" : null,
      };

      if (this.productsData.length === 0) {
        alert("Please add at least one item.");
        return false;
      }

      return !this.errors.canteenName;
    },
    validateField(field) {
      if (field === "canteenName") {
        this.errors.canteenName = !this.canteenName ? "Please select a canteen" : null;
      }
    },
    validateItemForm() {
      this.errors.itemname = !this.itemname ? "Please select an item" : null;
      this.errors.itemCount = !this.itemCount || this.itemCount <= 0 ? "Please enter a valid quantity" : null;

      return !this.errors.itemname && !this.errors.itemCount;
    },
    addproducts() {
      if (this.validateItemForm()) {
        const proddata = {
          itemId: this.itemname,
          itemName: this.itemnamestr,
          itemCount: this.itemCount,
          actualItemCount: 0,
          unit: this.unit,
          altItemCount: this.altItemCount,
          altUnit: this.alternativeUnit,
          expireDate: this.expdatedate,
        };
        this.productsData.push(proddata);
        this.itemname = "";
        this.itemName = "";
        this.noofitems = "";
        this.itemnamestr = "";
        this.itemCount = null;
        this.unit = "";
        this.altItemCount = null;
        this.alternativeUnit = "";
        this.expdatedate = null;
        this.errors.itemname = null;
        this.errors.itemCount = null;
      }
    },
    editlistitem(index) {
      this.itemeditobj = index;
    },
    onitemChange() {
      const selectedItemName = this.itemname;
      console.log("this.itemname", this.itemname);
      console.log("this.Itemlist", this.Itemlist);
      const selectedItemObject = this.Itemlist.find(
        (item) => item.id === selectedItemName
      );
      console.log("selectedItemObject", selectedItemObject);
      this.itemnamestr = selectedItemObject.itemName;
      this.unit = selectedItemObject.unit;
      this.ratePerUnit = selectedItemObject.rate;
      this.alternativeUnit = selectedItemObject.alternativeUnit;
      this.convertionRate = selectedItemObject.conversionRate;
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    deleteitem(index) {
      if (index > -1) {
        this.productsData.splice(index, 1);
      }
    },
    async getuserlist() {
      await axios
        .get(
          `https://dmsdev.datamatica.uk/v1/document/test/getalluser?role=Shopkeeper`
        )
        .then((res) => {
          this.userlist = res.data;
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    getItemName(itemId) {
      const item = this.Itemlist.find((item) => item.id === itemId);
      return item ? item.itemName : "Unknown Item";
    },
    async getItemlist() {
      await axios
        .get(`https://dmsdev.datamatica.uk/v1/document/test/getallitems`)
        .then((res) => {
          this.Itemlist = res.data;
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    async getcanteene() {
      await axios
        .get(`https://dmsdev.datamatica.uk/v1/document/test/listcanteens`)
        .then((res) => {
          this.canteenlist = res.data;
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    async submitForm() {
      if (this.orderId != "0") {
        this.edititem();
      } else {
        this.saveitem();
      }
    },
    async edititem() {
      const itemDetails = {
        id: this.id,
        userId: this.userid,
        canteenName: this.canteenName,
        orderCode: this.orderCode,
        productsData: this.productsData,
        status: this.status,
        date: this.date,
        shopkeeper: this.shopkeeper,
        deliveryDate: this.deliverydate,
      };
      await axios
        .put(
          `https://dmsdev.datamatica.uk/v1/document/test/updateorder?id=${this.orderId}`,
          itemDetails
        )
        .then((res) => {
          console.log(res.data);
          this.onclosed();
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
      console.log(itemDetails);
    },
    async saveitem() {
      const itemDetails = {
        id: this.id,
        userId: this.userid,
        canteenName: this.canteenName,
        orderCode: this.orderCode,
        productsData: this.productsData,
        status: this.status,
        date: this.date,
        shopkeeper: this.shopkeeper,
        deliveryDate: this.deliverydate,
      };
      await axios
        .post(
          `https://dmsdev.datamatica.uk/v1/document/test/createorder`,
          itemDetails
        )
        .then((res) => {
          console.log(res.data);
          this.onclosed();
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
      console.log(itemDetails);
    },
    async getorders() {
      await axios
        .get(
          `https://dmsdev.datamatica.uk/v1/document/test/getorderbyid?id=${this.orderId}`
        )
        .then((res) => {
          console.log(res.data);
          this.id = res.data.id;
          this.canteenName = res.data.canteenName;
          this.productsData = res.data.productsData;
          this.status = res.data.status;
          this.date = res.data.date.split("T")[0];
          this.shopkeeper = res.data.shopkeeper;
          this.deliverydate = res.data.deliveryDate.split("T")[0];
          this.orderCode = res.data.orderCode;
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    onclosed() {
      this.$router.push(`/Orderlist`);
    },
  },
};
</script>

<style scoped>
.maxheightelem {
  height: 40px;
}
.tableminheight {
  min-height: 150px;
  max-height: 300px;
  overflow: auto;
}
.buttonclass {
  width: 100px;
  margin-left: 10px;
}
.item-form {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 15px;
}
.divider-with-header {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.divider-with-header h5 {
  margin: 0;
  padding-right: 10px;
}
.divider-with-header hr {
  flex-grow: 1;
  border: none;
  border-top: 2px solid #333; /* Darker line */
}
.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}
.text-danger {
  color: red;
}

</style>
