<template>
  <div class="container col-md-12">
      <div class="row justify-content-between">
    <h2 class="headding col-md-3 mt-3">Category</h2>


<div class="col-md-2 mt-3">
<button type="button" class="btn btn-primary" @click.prevent="createcategorypopup('')">Add Category</button>
</div>

    </div>
    <div class="containers mt-5">
      <table class="table table-bordered">
<thead>
  <tr>
    <th>Name</th>
    <th>Active</th>
  </tr>
</thead>
<tbody>
  <tr v-for="categories in categorylist" :key="categories.id">
    <td>{{categories.name}}</td>
    <td>{{categories.active}}</td>
    </tr>

</tbody>
</table>

</div>
  </div>
  <createcategory
    v-if="uploadcategorymodal"
    :currentuserid = currentcatid
    @oncloseevent="closefun()"
  >
  </createcategory>
</template>

<script>
import axios from 'axios'
import createcategory from '../CreateCategoryPopup/CreateCategoryPopup.vue'
export default {
  data() {
    return {
      username: '',
      password: '',
      categorylist:[],
      uploadcategorymodal:false,
      currentcatid:"",
    };
  },
    components: {
      createcategory
  },
  mounted() {
  this.getcategorylist();
},
  methods: {
    async closefun(){     
      this.uploadcategorymodal = false;
      this.getcategorylist();
    },
    async createcategorypopup(userid){
        this.currentcatid = userid;
        this.uploadcategorymodal = true;
    },
    async getcategorylist(){
    await axios
      .get(
        `https://dmsdev.datamatica.uk/v1/document/test/listcategories`,

      ).then((res) => {

        this.categorylist = res.data;

      })
      .catch((err) => {
        console.log("failed");
        console.log(err);
      });
  },
  async createStock(stockid){
      console.log('createitem');
      this.$router.push(`/Createstock/${stockid}`);
    }
  }
};
</script>
<style>
.container{
  border: 2px solid #000; /* Black border */
border-radius: 10px; /* Curved edges */
border: 2px solid #000;
  border-radius: 10px;
  margin-top: 30px;
  min-height: 100%;
}
.headding{
  margin-left: 10px;
}
.table th {
    background-color: #f0f0f0; /* Gray background for header */
  }
  .table-bordered {
    border: none; /* Remove border */
  }
  .search-box {
display: inline-block;
border-radius: 10px;
background-color: #f3f3f4;
padding: 5px 10px;
}

.search-input {
border: none;
outline: none;
background: none;
width: 200px;
padding: 5px;
font-size: 16px;
}
</style>