<template>
  <div class="item-form col-md-12 p-4">
    <form>
      <div class="form-row d-flex justify-content-between">
        <div class="form-group col-md-3">
          <label for="itemName">Item Name<sup><font-awesome-icon icon="asterisk" class="text-danger"/></sup>:</label>
          <select id="itemName" v-model="itemName" @change="onitemChange()">
            <option value="">-- Select Item --</option>
            <option v-for="Item in Itemlist" :key="Item.itemName" :value="Item.itemName">{{ Item.itemName }}</option>
          </select>
          <span v-if="errors.itemName" class="text-danger">{{ errors.itemName }}</span>
        </div>
        <div class="form-group col-md-3">
          <label for="catagory">Category:</label>
          <select id="catagory" v-model="catagory" disabled class="custom-disabled">
            <option value="">-- Select category --</option>
            <option v-for="category in categorylist" :key="category.name" :value="category.name">{{ category.name }}</option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="supplier">Supplier<sup><font-awesome-icon icon="asterisk" class="text-danger"/></sup>:</label>
          <select id="supplier" v-model="supplier" required>
            <option value="">-- Select supplier --</option>
            <option v-for="supplier in supplierlist" :key="supplier.id" :value="supplier.name">{{ supplier.name }}</option>
          </select>
          <span v-if="errors.supplier" class="text-danger">{{ errors.supplier }}</span>
        </div>
      </div>
      <div class="form-row d-flex justify-content-between">
        <div class="form-group col-md-3">
          <label for="unit">Unit Of Measurement:</label>
          <select id="unit" v-model="unit" disabled class="custom-disabled">
            <option value="">-- Select Unit --</option>
            <option value="CTN">CTN</option>
            <option value="BDL">BDL</option>
            <option value="CS">CS</option>
            <option value="CAN">CAN</option>
            <option value="PC">PC</option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="quantity">Quantity<sup><font-awesome-icon icon="asterisk" class="text-danger"/></sup>:</label>
          <input type="number" id="quantity" v-model="quantity" />
          <span v-if="errors.quantity" class="text-danger">{{ errors.quantity }}</span>
        </div>
        <div class="form-group col-md-3">
          <label for="ratePerUnit">Rate:</label>
          <input type="number" id="ratePerUnit" v-model="ratePerUnit" disabled class="custom-disabled"/>
        </div>
      </div>
      <div class="form-row d-flex justify-content-between">
        <div class="form-group col-md-3">
          <label for="alternativeUnit">Alternative Unit:</label>
          <select id="alternativeUnit" v-model="alternativeUnit" disabled class="custom-disabled">
            <option value="">-- Select Alternative Unit --</option>
            <option value="CTN">CTN</option>
            <option value="BDL">BDL</option>
            <option value="CS">CS</option>
            <option value="CAN">CAN</option>
            <option value="PC">PC</option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="alternativeQuantity">Alternative Quantity:</label>
          <input type="number" id="alternativeQuantity" v-model="alternativeQuantity"/>
        </div>
        <div class="form-group col-md-3">
          <label for="alternativeRatePerUnit">Alternative Rate:</label>
          <input type="number" step="0.01" id="alternativeRatePerUnit" v-model="alternativeRatePerUnit"/>
        </div>
      </div>

      <div class="form-row d-flex justify-content-between">
        <div class="form-group col-md-3"></div>
        <div class="form-group col-md-3"></div>
        <div class="form-group col-md-3"></div>
      </div>

      <div class="form-row d-flex justify-content-between">
        <div class="form-group col-md-3">
          <label v-if="convertionRate">Conversion Rate (One {{ unit }} = {{ convertionRate }} {{ alternativeUnit }})</label>
          <label v-else>Conversion Rate</label>
          <input type="text" id="convunitOfMeasurement" v-model="convertionRate"/>
        </div>
        <div class="form-group col-md-3">
          <label for="purchaseDate">Purchase Date:</label>
          <input type="date" id="purchaseDate" v-model="purchaseDate" />
        </div>
        <div class="form-group col-md-3">
          <label for="expireDate">Expiry Date:</label>
          <input type="date" id="expireDate" v-model="expireDate" />
        </div>
      </div>

      <div class="form-row d-flex justify-content-between">
        <div class="form-group col-md-3">
          <label for="batchId">Batch Id:</label>
          <input type="text" id="batchId" v-model="batchId" />
        </div>
        <div class="form-group col-md-3"></div>
        <div class="form-group col-md-3"></div>
      </div>

      <div class="d-flex justify-content-end">  
        <button class="buttonclass btn btn-primary mr-2" type="submit" @click.prevent="submitForm()">Submit</button>
        <button type="button" class="btn btn-danger buttonclass" @click.prevent="onclosed">Cancel</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: {
    stockId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      Itemlist: [],
      supplierlist: [],
      categorylist: [],
      id: "",
      itemName: "",
      quantity: null,
      unit: "",
      rate: "",
      unitOfMeasurement: "",
      catagory: "",
      batchId: "",
      status: "",
      supplier: "",
      ratePerUnit: null,
      active: true,
      purchaseDate: null,
      expireDate: null,
      alternativeQuantity: null,
      alternativeUnit: "",
      alternativeRatePerUnit: null,
      actualQuantity: null,
      actualUnit: "",
      convertionRate: null,
      errors: {
        itemName: null,
        supplier: null,
        quantity: null,
      },
    };
  },
  mounted() {
    console.log("this.stockId", this.stockId);
    if (this.stockId != "0") {
      this.getstock();
    }
    this.getcategorylist();
    this.getsupplierlist();
    this.getItemlist();
  },
  methods: {
    onitemChange() {
      const selectedItemName = this.itemName;
      const selectedItemObject = this.Itemlist.find(item => item.itemName === selectedItemName);
      console.log("selectedItemObject", selectedItemObject);
      this.catagory = selectedItemObject.catagory;
      this.unit = selectedItemObject.unit;
      this.ratePerUnit = selectedItemObject.rate;
      this.alternativeUnit = selectedItemObject.alternativeUnit;
      this.convertionRate = selectedItemObject.conversionRate;
    },
    async getItemlist() {
      await axios
        .get(`https://dmsdev.datamatica.uk/v1/document/test/getallitems`)
        .then((res) => {
          this.Itemlist = res.data;
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    async getsupplierlist() {
      await axios
        .get(`https://dmsdev.datamatica.uk/v1/document/test/listsuppliers`)
        .then((res) => {
          this.supplierlist = res.data;
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    async getcategorylist() {
      await axios
        .get(`https://dmsdev.datamatica.uk/v1/document/test/listcategories`)
        .then((res) => {
          this.categorylist = res.data;
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    async getstock() {
      await axios
        .get(`https://dmsdev.datamatica.uk/v1/document/test/getstockbyid?id=${this.stockId}`)
        .then((res) => {
          console.log(res.data);
          this.id = res.data.id;
          this.itemName = res.data.itemName;
          this.quantity = res.data.quantity;
          this.unit = res.data.unit;
          this.catagory = res.data.catagory;
          this.batchId = res.data.batchId;
          this.status = res.data.status;
          this.supplier = res.data.supplier;
          this.expireDate = res.data.expireDate ? new Date(res.data.expireDate).toISOString().split('T')[0] : null;
          this.purchaseDate = res.data.purchaseDate ? new Date(res.data.purchaseDate).toISOString().split('T')[0] : null;
          this.ratePerUnit = res.data.ratePerUnit;
          this.active = res.data.active;
          this.alternativeQuantity = res.data.alternativeQuantity;
          this.alternativeUnit = res.data.alternativeUnit;
          this.alternativeRatePerUnit = res.data.alternativeRatePerUnit;
          this.actualQuantity = res.data.actualQuantity;
          this.actualUnit = res.data.actualUnit;
          this.convertionRate = res.data.convertionRate;
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    validateForm() {
      this.errors = {
        itemName: !this.itemName ? "Please select an item name" : null,
        supplier: !this.supplier ? "Please select a supplier" : null,
        quantity: !this.quantity || this.quantity <= 0 ? "Please enter a valid quantity" : null,
      };

      return !this.errors.itemName && !this.errors.supplier && !this.errors.quantity;
    },
    async submitForm() {
      if (this.validateForm()) {
        if (this.stockId == "0") {
          this.createstock();
        } else {
          this.edittestock();
        }
      }
    },
    async createstock() {
      const stockDetails = {
        id: this.id,
        itemName: this.itemName,
        quantity: this.quantity != null ? parseFloat(this.quantity) : 0,
        unit: this.unit,
        catagory: this.catagory,
        batchId: this.batchId,
        status: this.status,
        supplier: this.supplier,
        expireDate: this.expireDate != null ? this.expireDate.toString() : null,
        purchaseDate: this.purchaseDate != null ? this.purchaseDate.toString() : null,
        ratePerUnit: this.ratePerUnit != null ? parseFloat(this.ratePerUnit) : 0,
        active: this.active,
        alternativeQuantity: this.alternativeQuantity != null ? parseFloat(this.alternativeQuantity) : 0,
        alternativeUnit: this.alternativeUnit,
        alternativeRatePerUnit: this.alternativeRatePerUnit != null ? parseFloat(this.alternativeRatePerUnit) : 0,
        actualQuantity: this.actualQuantity != null ? parseFloat(this.actualQuantity) : 0,
        actualUnit: this.actualUnit,
        convertionRate: this.convertionRate != null ? parseFloat(this.convertionRate) : 0,
      };
      await axios
        .post(`https://dmsdev.datamatica.uk/v1/document/test/createstock`, stockDetails)
        .then((res) => {
          console.log(res.data);
          this.onclosed();
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    async edittestock() {
      const stockDetails = {
        id: this.id,
        itemName: this.itemName,
        quantity: this.quantity != null ? parseFloat(this.quantity) : 0,
        unit: this.unit,
        catagory: this.catagory,
        batchId: this.batchId,
        status: this.status,
        supplier: this.supplier,
        expireDate: this.expireDate != null ? this.expireDate.toString() : null,
        purchaseDate: this.purchaseDate != null ? this.purchaseDate.toString() : null,
        ratePerUnit: this.ratePerUnit != null ? parseFloat(this.ratePerUnit) : 0,
        active: this.active,
        alternativeQuantity: this.alternativeQuantity != null ? parseFloat(this.alternativeQuantity) : 0,
        alternativeUnit: this.alternativeUnit,
        alternativeRatePerUnit: this.alternativeRatePerUnit != null ? parseFloat(this.alternativeRatePerUnit) : 0,
        actualQuantity: this.actualQuantity != null ? parseFloat(this.actualQuantity) : 0,
        actualUnit: this.actualUnit,
        convertionRate: this.convertionRate != null ? parseFloat(this.convertionRate) : 0,
      };
      await axios
        .put(`https://dmsdev.datamatica.uk/v1/document/test/updatestock?id=${this.stockId}`, stockDetails)
        .then((res) => {
          console.log(res.data);
          this.onclosed();
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    onclosed() {
      this.$router.push(`/AllStock`);
    }
  }
};
</script>

<style scoped>
.buttonclass {
  width: 100px;
  margin-left: 10px;
}
.item-form {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.custom-disabled:disabled {
  background-color: white;
  color: black; /* Optional: Change text color if needed */
}
.text-danger {
  color: red;
}
</style>
