<template>
    <div class="item-form col-md-12 mt-4">
      <form>
        <div class="d-flex justify-content-around">
        <div class="form-group col-md-5">
          <label for="itemName">Item Name:</label>
          <input type="text" id="itemName" v-model="itemName" required />
        </div>

        <div class="form-group col-md-5">
          <label for="itemCode">Item Code:</label>
          <input type="text" id="itemCode" v-model="itemCode" required />
        </div>
  </div>
  <div class="d-flex justify-content-around">
        <div class="form-group col-md-5">
          <label for="catagory">Catagory:</label>
          <select id="catagory" v-model="catagory">
            <option v-for="categories in categorylist" :key="categories.id" :value="categories.name">{{ categories.name }}</option>
          </select>
        </div>
  
        <div class="form-group col-md-5">
          <label for="unitOfMesurement">Unit Of Mesurement:</label>
          <select id="unitOfMesurement" v-model="unitOfMesurement" required>
            <option value="">-- Select Unit --</option>
            <option value="CTN">CTN</option>
            <option value="BDL">BDL</option>
            <option value="CS">CS</option>
            <option value="CAN">CAN</option>
            <option value="PC">PC</option>
          </select>
        </div>
      </div>
      <div class="d-flex justify-content-around">
        <div class="form-group col-md-5">
          <label for="rate">Rate:</label>
          <input type="number" id="rate" v-model="rate" required />
        </div>
        <div class="form-group col-md-5">
          <label for="alternativeunit">Alternative Unit:</label>
          <select id="alternativeunit" v-model="alternativeunit" required>
            <option value="">-- Select alternative Unit --</option>
            <option value="CTN">CTN</option>
            <option value="BDL">BDL</option>
            <option value="CS">CS</option>
            <option value="CAN">CAN</option>
            <option value="PC">PC</option>
          </select>
        </div>
      </div>
      <div class="d-flex justify-content-around">
        <div class="form-group col-md-5">
          <label for="conversionrate">Conversion Rate:</label>
          <input type="number" id="conversionrate" v-model="conversionrate" required />
        </div>
         <div class="form-group col-md-5">
          <label for="availablestock">Available Stock:</label>
          <input type="number" id="availablestock" v-model="availablestock" required />
       </div>
      </div>
        <div class="d-flex justify-content-end">
    <button class="buttonclass btn btn-primary mr-2" type="submit" @click.prevent="submitForm()">Submit</button>
    <button type="button" class="btn btn-danger buttonclass" @click.prevent="($router.push(`/ItemPage`))">Cancel</button>
</div>

      </form>
    </div>
  </template>
  
  <script>
 import axios from 'axios'
export default {
  props: {
    itemId:{
            type: String,
            default: ''
        }      
    },
  data() {
    return {
      availablestock:0,
      id:'',
      itemCode: '',
      itemName: '',
      catagory: '',
      unitOfMesurement: '',
      rate: null,
      active: true,
      categorylist:[],
      alternativeunit:"",
      itemcount:"",
      conversionrate:null,
    };
  },
  mounted() {
    console.log("this.itemId",this.itemId);
    if(this.itemId!="0"){
    this.getitem();
    }
    this.getcategorylist();
  },
  methods: {
    
    async getcategorylist(){
    await axios
      .get(
        `https://dmsdev.datamatica.uk/v1/document/test/listcategories`,

      ).then((res) => {

        this.categorylist = res.data;

      })
      .catch((err) => {
        console.log("failed");
        console.log(err);
      });
  },
    async submitForm() {
      if(this.itemId!="0"){
    this.edititem();
    }
    else{
      this.saveitem();
    }
    },
    async edititem() {
      const itemDetails = {
        id: this.id,
        itemCode: this.itemCode,
        itemName: this.itemName,
        catagory: this.catagory,
        unit: this.unitOfMesurement,
        rate: this.rate,
        active: this.active,
        alternativeUnit: this.alternativeunit,
        conversionRate: this.conversionRate,
        itemCount: this.availablestock
      };
      await axios
    .put(
      `https://dmsdev.datamatica.uk/v1/document/test/updateitem`,itemDetails,

    )
    .then((res) => {
    console.log(res.data);
    this.onclosed();
  })
    .catch((err) => {
      console.log("failed");
      console.log(err);
    })
      console.log(itemDetails);
      // You can add more logic here, such as sending the data to an API
    },
    async saveitem() {
      const itemDetails = {
        id: this.id,
        itemCode: this.itemCode,
        itemName: this.itemName,
        catagory: this.catagory,
        unit: this.unitOfMesurement,
        rate: this.rate,
        active: this.active,
        alternativeUnit: this.alternativeunit,
        conversionRate: this.conversionrate,
        itemCount: this.availablestock
      };
      await axios
    .post(
      `https://dmsdev.datamatica.uk/v1/document/test/createitem`,itemDetails,

    )
    .then((res) => {
    console.log(res.data);
    this.onclosed();
  })
    .catch((err) => {
      console.log("failed");
      console.log(err);
    })
      console.log(itemDetails);
      // You can add more logic here, such as sending the data to an API
    },
    async getitem() {
      await axios
    .get(
      `https://dmsdev.datamatica.uk/v1/document/test/getitem?itemId=${this.itemId}`,

    )
    .then((res) => {
    console.log(res.data);
    this.id= res.data.id;
    this.itemCode= res.data.itemCode;
    this.itemName= res.data.itemName;
    this.catagory= res.data.catagory;
    this.unitOfMesurement= res.data.unit;
    this.rate= res.data.rate;
    this.active= res.data.active;
    this.alternativeunit= res.data.alternativeUnit;
    this.availablestock= res.data.itemCount;
    this.conversionrate= res.data.conversionRate;
  })
    .catch((err) => {
      console.log("failed");
      console.log(err);
    })
      // You can add more logic here, such as sending the data to an API
    },
    onclosed(){
      this.$router.push(`/ItemPage`);
    }
  }
};
</script>
  
  <style scoped>
  .buttonclass{
    width:100px;
    margin-left: 10px;
  }
  .item-form {
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  </style>