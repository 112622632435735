<template>
  <div class="body-small pace-done" :class="currentTheme">
    <div id="wrapper">
      <nav
      v-if="getNavbarstatus == 'true'"
        id="mobilemenu"
        class="navbar-default navbar-static-side"
        role="navigation"
      >
        <div>
          <ul class="nav metismenu" id="side-menu">
            <li class="nav-header">
              <div class="dropdown profile-element mb-1">
                <!-- <img src="../../assets/img/IDSLogo.png" alt="Avatar" /> -->
                <div class="headderdiv">AL BUSTAN</div>
              </div>
              <div class="logo-element">
                <!-- <img src="../../assets/img/EIDSA_logo_mobile.png" alt="Avatar" /> -->
                <div class="headderdivcolapsed">AL BUSTAN</div>
              </div>
            </li>
            <li>
              <router-link :to="'/home'" class="nav-link" @click.prevent="close"
                ><font-awesome-icon icon="home" class="iconwhite" />
                <span v-if="notcollapsed"> Dashboard </span>
              </router-link>
            </li>
            <li>
              <router-link
                :to="'/OrderCreate/0'"
                class="nav-link"
                @click.prevent="close"
                ><font-awesome-icon icon="plus-circle" class="iconwhite" />
                <span v-if="notcollapsed"> Create Order </span>
              </router-link>
            </li>
            <li>
              <router-link
                :to="'/CreateStock/0'"
                class="nav-link"
                @click.prevent="close"
                ><font-awesome-icon icon="cart-plus" class="iconwhite" />
                <span v-if="notcollapsed"> Add Stock </span>
              </router-link>
            </li>
            <li>
              <router-link
                :to="'/AllStock'"
                class="nav-link"
                @click.prevent="close"
                ><font-awesome-icon icon="shopping-basket" class="iconwhite" />
                <span v-if="notcollapsed"> All Stocks </span>
              </router-link>
            </li>
            <li>
              <router-link
                :to="'/Orderlist'"
                class="nav-link"
                @click.prevent="close"
                ><font-awesome-icon icon="bullhorn" class="iconwhite" />
                <span v-if="notcollapsed"> Orders </span>
              </router-link>
            </li>
            <li>
              <router-link
                :to="'/ItemPage'"
                class="nav-link"
                @click.prevent="close"
                ><font-awesome-icon icon="shopping-bag" class="iconwhite" />
                <span v-if="notcollapsed"> Items </span>
              </router-link>
            </li>
            <li>
              <router-link
                :to="'/category'"
                class="nav-link"
                @click.prevent="close"
                ><font-awesome-icon icon="fa-th-list" class="iconwhite" />
                <span v-if="notcollapsed"> Category </span>
              </router-link>
            </li>
            <li>
              <router-link
                :to="'/supplylist'"
                class="nav-link"
                @click.prevent="close"
                ><font-awesome-icon icon="cubes" class="iconwhite" />
                <span v-if="notcollapsed"> Suppliers </span>
              </router-link>
            </li>
            <!-- <li>
              <router-link
                :to="'/AvailableStock'"
                class="nav-link"
                @click.prevent="close"
                ><font-awesome-icon icon="fa-th-list" class="iconwhite" />
                <span v-if="notcollapsed"> Available Products </span>
              </router-link>
            </li> -->
            <!-- <li>
              <router-link
                :to="'/lowstockalert'"
                class="nav-link"
                @click.prevent="close"
                ><font-awesome-icon
                  icon="exclamation-triangle"
                  class="iconwhite"
                />
                <span v-if="notcollapsed"> LowStock Alert </span>
              </router-link>
            </li> -->
            
            <li>
              <router-link
                :to="'/canteenlist'"
                class="nav-link"
                @click.prevent="close"
                ><font-awesome-icon icon="coffee" class="iconwhite" />
                <span v-if="notcollapsed"> Canteen </span>
              </router-link>
            </li>
            <li>
              <router-link
                :to="'/Users'"
                class="nav-link"
                @click.prevent="close"
                ><font-awesome-icon icon="users" class="iconwhite" />
                <span v-if="notcollapsed"> Users </span>
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
      <div id="page-wrapper">
        <div v-if="getNavbarstatus == 'true'" class="row border-bottom ">
          <nav
            class="navbar navbar-static-top"
            role="navigation"
            style="margin-bottom: 0"
          >
            <div class="navbar-header align-self-center">
              <a
                aria-label="Collapsible menu icon"
                tabindex="0"
                class="btn-cps ml-lg-4 m-4"
                href="#"
                @click.prevent="collapse()"
              >
                <!-- <i class="fa fa-angle-left" v-if="isActive">{{ isActive }}</i> -->
                <font-awesome-icon icon="bars" class="blackicon" />
              </a>
              
            </div>
            <ul class="nav navbar-top-links navbar-right align-self-lg-auto">
              <font-awesome-icon
                icon="power-off"
                class="m-auto p-3 iconwhite"
                @click.prevent="logout()"
              />
              <font-awesome-icon
                :icon="['far', 'bell']"
                class="m-auto p-3 iconwhite"
              />
              <div class="user-box">
                <img
                  src="../../assets/img/user_grey.png"
                  alt="Sample User Image"
                  class="user-image"
                />
                <div class="user-info">
                  <span class="username">{{ currentusername }}</span>
                </div>
              </div>

              <font-awesome-icon icon="cog" class="m-auto p-3 iconwhite" />
            </ul>
          </nav>
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  defineComponent,
  watch,
  ref,
  watchEffect,
  computed,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "Navbar",
  setup() {
    const store = useStore();
    let auth0Client = ref();
    let currentTheme = ref(localStorage.getItem("theme-color"));
    let valnow = ref("");
    let querylist = ref([]);
    let isActive = ref(true);
    let isRightActive = ref(false);
    let querycount = ref();
    let studies = ref([]);
    let arr = ref([]);
    let mainmenu = ref([true, true, true, true]);
    let submenu = ref([false, false, false, false]);
    let collapseSideBar = ref(false);
    let showArrows = ref(false);
    let createstudypopup = ref(false);
    let notcollapsed = true;
    let expandclass = "nav nav-second-level collapse";

    const router = useRouter();
    let getNavbarstatus = computed(function () {
      return store.getters.getifloggedIn;
    });
    let currentusername = computed(function () {
      return store.getters.getusername;
    });

    async function anchorlink() {
      let listItems = document.getElementsByClassName("nav-link");
      for (let i = 0; i <= listItems.length - 1; i++) {
        //console.log("The value are",listItems[i].innerText)
      }
    }

    async function logout() {
      console.log("Logout");
      store.dispatch("setloggedIn", "false");
      localStorage.clear(); 
      router.push(`/`);
    }

    async function expandSidebar() {
      let x = document.getElementsByClassName("theme-menu")[0];
      if (x.style.display == "block") {
        x.style.display = "none";
      } else x.style.display = "block";
    }
    window.matchMedia("(min-width:769px)").addListener(initNav);
    window.matchMedia("(max-width:769px)").addListener(initArrow);
    function initArrow() {
      if (document.body.classList.contains("mini-navbar")) {
        document.body.classList.remove("mini-navbar");
      }
    }
    //mobile navbar initialised
    function initNav() {
      isActive.value = true;
      isRightActive.value = false;
      if (window.innerWidth > 769) {
        if (document.body.classList.contains("mini-navbar")) {
          document.body.classList.remove("mini-navbar");
          document
            .getElementById("mobilemenu")
            .classList.toggle("navbar-static-side");
        } else {
          document
            .getElementById("mobilemenu")
            .classList.add("navbar-default", "navbar-static-side");
        }
      }
    }
    //mobile and tab navbar close button

    function collapse() {
      isActive.value = !isActive.value;
      isRightActive.value = !isRightActive.value;
      if (screen.width > 768) {
        document.body.classList.toggle("mini-navbar");
        notcollapsed = false;
      } else {
        document.body.classList.toggle("mini-navbar");
        document
          .getElementById("mobilemenu")
          .classList.toggle("navbar-static-side");
      }
    }
    //check the screen size and resize navbar

    async function expandmenu() {
      let expitem = document.getElementById("expitm");
      expitem.classList.toggle("collapse");
    }
    async function expandfun(id, index, type) {
      let expitem = document.getElementById(id);
      if (type === "mainmenu") {
        mainmenu.value[index] = !mainmenu.value[index];
      } else {
        submenu.value[index] = !submenu.value[index];
      }
      expitem.classList.toggle("collapse");
    }
    async function keepexpanded(id) {
      let expitem = document.getElementById(id);
      expitem.className = "nav nav-second-level";
    }
    watchEffect(() => {
      collapseSideBar.value = store.getters.getCollappseShow;
      if (collapseSideBar.value == true && isRightActive.value == false) {
        showArrows.value = false;
        collapse();
      } else if (collapseSideBar.value == false && showArrows.value == false) {
        showArrows.value = true;
        isRightActive.value = false;
        isActive.value = true;
        if (document.body.classList.contains("mini-navbar")) {
          document.body.classList.remove("mini-navbar");
        }
      } else {
        showArrows.value = false;
      }
    });
    return {
      showArrows,
      collapseSideBar,
      logout,
      currentusername,
      currentTheme,
      notcollapsed,
      expandclass,
      valnow,
      auth0Client,
      studies,
      querycount,
      querylist,
      isActive,
      isRightActive,
      getNavbarstatus,
      anchorlink,
      logout,
      collapse,
      expandmenu,
      expandfun,
      initNav,
      close,
      mainmenu,
      submenu,
      expandSidebar,
    };
  },
};
</script>

<style scoped >
@import "../../assets/css/style.css";
.iconwhite {
  font-size: 24px;
  cursor: pointer;
}
.search-box {
  display: inline-block;
  border-radius: 10px;
  background-color: #f3f3f4;
  padding: 5px 10px;
}

.search-input {
  border: none;
  outline: none;
  background: none;
  width: 200px;
  padding: 5px;
  font-size: 16px;
}
@media (max-width: 768px) {
  .navbar.navbar-static-top {
    padding: 0 1rem !important;
  }

  .navbar-header {
    align-self: center;
  }

  .minimalize-styl-2 {
    margin: 0 !important;
  }

  .nav > li > a i {
    font-size: 1em;
  }

  #wrapper {
    position: relative !important;
  }

  .pace-done #page-wrapper,
  .body.mini-navbar #page-wrapper {
    position: relative;
  }

  #mobilemenu.navbar-static-side,
  #mobilemenu.navbar-default {
    transition: all ease-in-out 400ms !important;
  }

  .mini-navbar #mobilemenu > div {
    width: 87px;
    transition: width ease-in-out 400ms !important;
  }

  .angle-down-right {
    float: left;
    margin-left: 45%;
  }
}

.theme-default {
  /* Button color change property */
  --primary-color: #145faf;
  --hvr-color: #2d91fb;
  --secondary-color: #618bd7;

  /*   Dashboard title color change property */
  --pagination-active-clr: rgb(97, 139, 215);
  --icon-color: #68696b;
  --icon-white: #ffffff;
  --background-primary: #145faf;
  --background-secondary: #618bd7;
  --pop-up-background: #618bd7;
  --pop-up-background-light: #d9e4ff;
}

.btn-cps {
  color: #fff;
  float: inherit;
  transition: background-color ease-in-out 300ms;
  font-size: 30px;
}

.btn-cps:hover {
  color: #fff;
}

.btn-cps:active {
  color: #fff;
  background-color: #4882da;
  border-color: #4882da;
}

.submenuitem {
  width: 100% !important;
}

a {
  text-decoration: none !important;
}

.dropdown {
  height: 100%;
}

.profile-pic {
  border-radius: 50%;
  width: 29px;
}

.logindetails {
  margin-left: 10px;
  text-align: center;
  margin-top: 10px;
  -webkit-transition: display ease-in-out 400ms;
  transition: display ease-in-out 400ms;
}

.nav.navbar-top-links > li > a:hover,
.nav.navbar-top-links > li > a:focus {
  color: #ffffff !important;
}

.fullwidthquery {
  width: 100%;
}

.thirdlevel {
  margin-left: 25px;
}

.navbar-fixed-top,
.navbar-static-top {
  background: white !important;
}

.query-icon {
  position: relative;
  color: white !important;
}

.query-count {
  position: absolute;
  right: 51px;
  top: 15px;
}

.query-count:empty {
  display: none;
}

.login-user-details {
  padding: 0 0.8rem;
}

.user-details > div {
  cursor: pointer;
}

.user-details {
  padding: 0 0.9rem 0.5rem 0.9rem;
}

.profile-block {
  position: absolute;
  color: var(--bs-dark);
  font-family: var(--bs-font-sans-serif);
  background-color: #ffffff;
  width: max-content;
  border: medium none;
  border-radius: 3px;
  display: none;
  font-size: 12px;
  top: 60px;
  right: 10px;
  text-shadow: none;
  z-index: 1000;
  box-shadow: 0 24px 54px rgba(0, 0, 0, 0.15),
    0 4.5px 13.5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 24px 54px rgba(0, 0, 0, 0.15),
    0 4.5px 13.5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 24px 54px rgba(0, 0, 0, 0.15),
    0 4.5px 13.5px rgba(0, 0, 0, 0.08);
}

.position-relative {
  position: relative;
}

.profile-block > div > *:not(span) {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.profile-image {
  max-width: 100%;
  width: 30px;
  height: 30px;
}

.animate {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.btn-raise-query {
  color: #68696b;
  padding: 0.11rem 0.4rem;
  border-radius: 30px;
  text-align: center;
  font-size: 10px;
  background-color: #ffffff !important;
}

.nav-link:hover,
.nav-link:focus {
  color: #68696b !important;
}
.angle-down-right {
  transition: transform 200ms;
}
.rotate-left {
  transform: rotate(90deg);
}
.rotate-down {
  transform: rotate(-90deg);
}
.user-box {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 25px;
  background-color: #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 43px;
  margin: auto;
}

.user-image {
  border-radius: 50%;
  margin-right: 10px;
}

.user-info {
  flex-grow: 1; /* Expand to fill remaining space */
}

.username {
  font-weight: bold;
  color: #333;
}
.blackicon {
  color: black;
}
.headderdiv {
  padding: 7px;
  padding-left: 45px;
  font-size: 24px;
  font-weight: 600;
}
.headderdivcolapsed {
  color: black;
}
.mainmenu {
  color: #68696b;
  margin-left: 10px;
}
</style>
