<template>
    <div class="container col-md-12">
        <div class="d-flex justify-content-between mt-3">
  <h2 class="headding">Canteen</h2>
    <button type="button" class="btn btn-primary ml-3 col-md-2" @click.prevent="createCanteenpopup()">Create Canteen</button>
</div>

      <div class="containers mt-5">
        <table class="table table-bordered">
  <thead>
    <tr>
      <th>Name</th>
      <th>Active</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="canteen in canteenlist" :key="canteen.id">
      <td>{{canteen.name}}</td>
      <td>{{canteen.active}}</td>
      </tr>

  </tbody>
</table>


</div>
    </div>
    <canteenPopup
    v-if="uploadcanteenmodal"
    :currentsupplyid = currentsupplyid
    @oncloseevent="closefun()"
  >
  </canteenPopup>
  </template>
  
  <script>
  import axios from 'axios'
  import canteenPopup from "../canteenpopup/canteenpopup.vue";
  export default {
    data() {
      return {
        canteenlist:[],
        username: '',
        password: '',
        uploadcanteenmodal:false,
        currentsupplyid:"",
      };
    },
    components: {
        canteenPopup
  },
  mounted() {
    this.getcanteenlist();
  },
    methods: {
      async createCanteenpopup(){
        this.uploadcanteenmodal = true;
    },
    async getcanteenlist(){
      await axios
        .get(
          `https://dmsdev.datamatica.uk/v1/document/test/listcanteens`,

        ).then((res) => {

          this.canteenlist = res.data;

        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    async deletefun(userid) {
      const userConfirmed = confirm("Are you sure you want to delete this user?");
      if (userConfirmed) {
      await axios
        .delete(
          `https://dmsdev.datamatica.uk/v1/document/test/deletesupplier/${userid}`
        )
        .then((res) => {
            this.getsupplierlist();
          console.log(res.data);
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
      }
    },
      async closefun(){     
      this.uploadcanteenmodal = false;
      this.getcanteenlist();
    },
    }
  };
  </script>
  <style>
  .container{
    border: 2px solid #000; /* Black border */
  border-radius: 10px; /* Curved edges */
  border: 2px solid #000;
    border-radius: 10px;
    margin-top: 30px;
    min-height: 100%;
  }
  .headding{
    margin-left: 10px;
  }
  .table th {
      background-color: #f0f0f0; /* Gray background for header */
    }
    .table-bordered {
      border: none; /* Remove border */
    }
  </style>