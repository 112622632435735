<template>
    <div class="container">
        <div class="d-flex justify-content-between mt-3">
  <h2 class="headding">Users</h2>
    <button type="button" class="btn btn-primary ml-3 col-md-2" @click.prevent="createuserpopup('')">Create User</button>
</div>

      <div class="containers mt-5">
        <table class="table table-bordered">
  <thead>
    <tr>
      <th>User Name</th>
      <th>Full Name</th>
      <th>Role</th>
      <th>Email ID</th>
      <th>Edit</th>
      <th>Delete</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="user in userlist" :key="user.id">
      <td>{{user.userName}}</td>
      <td>{{user.fullName}}</td>
      <td>{{user.roles[0]}}</td>
      <td>{{user.email}}</td>
      <td><button type="button" class="btn btn-primary" @click.prevent="createuserpopup(user.id)">Edit</button></td>
      <td><button type="button" class="btn btn-danger" @click.prevent="deletefun(user.id)">Delete</button></td>
    </tr>

  </tbody>
</table>


</div>
    </div>
    <CteateuserPopup
    v-if="uploaduserupmodal"
    :currentuserid = currentuserid
    @oncloseevent="closefun()"
  >
  </CteateuserPopup>
  </template>
  
  <script>
  import axios from 'axios'
  import CteateuserPopup from "../CreateUser/CreateUserPopup.vue";
  export default {
    data() {
      return {
        userlist:[],
        username: '',
        password: '',
        uploaduserupmodal:false,
        currentuserid:"",
        roles: ["ADMIN", "User", "Guest", "Shopkeeper"],
      };
    },
    components: {
      CteateuserPopup
  },
  mounted() {
    this.getuserlist();
  },
    methods: {
      async createuserpopup(userid){
        this.currentuserid = userid;
        this.uploaduserupmodal = true;
    },
    async getuserlist(){
      await axios
        .get(
          `https://dmsdev.datamatica.uk/v1/document/test/getalluser`,

        ).then((res) => {

          this.userlist = res.data;

        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    async deletefun(userid) {
      const userConfirmed = confirm("Are you sure you want to delete this user?");
      if (userConfirmed) {
      await axios
        .delete(
          `https://dmsdev.datamatica.uk/v1/document/test/deleteuser?userId=${userid}`
        )
        .then((res) => {
          this.getuserlist();
          console.log(res.data);
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
      }
    },
      async closefun(){     
      this.uploaduserupmodal = false;
      this.getuserlist();
    },
    }
  };
  </script>
  <style>
  .container{
    border: 2px solid #000; /* Black border */
  border-radius: 10px; /* Curved edges */
  border: 2px solid #000;
    border-radius: 10px;
    margin-top: 30px;
    min-height: 100%;
  }
  .headding{
    margin-left: 10px;
  }
  .table th {
      background-color: #f0f0f0; /* Gray background for header */
    }
    .table-bordered {
      border: none; /* Remove border */
    }
  </style>