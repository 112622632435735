<template>
    <div class="container col-md-12">
        <div class="row justify-content-between">
  <h2 class="headding col-md-3 mt-3">Supplier</h2>
  <div class="col-md-4 d-flex align-items-center justify-content-between mt-3">
    <div class="mr-3">
    </div>
   </div>
</div>
<div class="d-flex justify-content-between">
        <div class="form-group col-md-4 p-2">
          <label for="searchitem">Supplier Name:</label>
          <input type="text" id="searchitem" v-model="searchitem" class="maxheightelem col-md-12" >
          
        </div>

  
        <div class="col-md-3 m-auto">
        <button class="buttonclass btn btn-success mr-2 h-50 mt-auto mb-auto col-md-4" type="submit" @click.prevent="getsupplierlist()">Search</button>
        </div>
        <div class="col-md-4 m-auto">
        </div>
        </div>
        <div class="d-flex justify-content-between mt-3 col-md-12 flex-row-reverse">
      <button type="button" class="btn btn-primary ml-3 col-md-2 btnclass" @click.prevent="createuserpopup('')">Create Supplier</button>
    </div>
      <div class="containers mt-1">
        <table class="table table-bordered">
  <thead>
    <tr>
      <th>Name</th>
      <th>Address</th>
      <th>Phone no</th>
      <th>Email</th>
      <th>Active</th>
      <th>Edit</th>
      <th>Delete</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="supplier in supplierlist" :key="supplier.id">
      <td>{{supplier.name}}</td>
      <td>{{supplier.address}}</td>
      <td>{{supplier.phoneNumber}}</td>
      <td>{{supplier.email}}</td>
      <td>{{supplier.active}}</td>
      <td><button type="button" class="btn btn-primary" @click.prevent="createuserpopup(supplier.id)">Edit</button></td>
      <td><button type="button" class="btn btn-danger" @click.prevent="deletefun(supplier.id)">Delete</button></td>
    </tr>

  </tbody>
</table>


</div>
    </div>
    <supplyPopup
    v-if="uploadsuppilerupmodal"
    :currentsupplyid = currentsupplyid
    @oncloseevent="closefun()"
  >
  </supplyPopup>
  </template>
  
  <script>
  import axios from 'axios'
  import supplyPopup from "../supplyPopup/supplyPopup.vue";
  export default {
    data() {
      return {
        searchitem:"",
        supplierlist:[],
        username: '',
        password: '',
        uploadsuppilerupmodal:false,
        currentsupplyid:"",
      };
    },
    components: {
        supplyPopup
  },
  mounted() {
    this.getsupplierlist();
  },
    methods: {
      async createuserpopup(userid){
        this.currentsupplyid = userid;
        this.uploadsuppilerupmodal = true;
    },
    async getsupplierlist(){
      await axios
        .get(
          `https://dmsdev.datamatica.uk/v1/document/test/listsuppliers?key=${this.searchitem}`,

        ).then((res) => {

          this.supplierlist = res.data;

        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    async deletefun(userid) {
      const userConfirmed = confirm("Are you sure you want to delete this user?");
      if (userConfirmed) {
      await axios
        .delete(
          `https://dmsdev.datamatica.uk/v1/document/test/deletesupplier/${userid}`
        )
        .then((res) => {
            this.getsupplierlist();
          console.log(res.data);
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
      }
    },
      async closefun(){     
      this.uploadsuppilerupmodal = false;
      this.getsupplierlist();
    },
    }
  };
  </script>
  <style>
  .container{
    border: 2px solid #000; /* Black border */
  border-radius: 10px; /* Curved edges */
  border: 2px solid #000;
    border-radius: 10px;
    margin-top: 30px;
    min-height: 100%;
  }
  .headding{
    margin-left: 10px;
  }
  .table th {
      background-color: #f0f0f0; /* Gray background for header */
    }
    .table-bordered {
      border: none; /* Remove border */
    }
  </style>