<template>
    <div class="modal__upload__wrapper">
      <div class="modal__upload__container">
        <div
          class="modal__upload__title d-flex justify-content-between py-2 px-3 text-dark"
        >
          <h4 class="my-0">Create Supplier</h4>
          <div class="cursor-pointer" @click="onclosed()">
            <font-awesome-icon icon="times-circle" class="iconwhite" />
          </div>
        </div>
        <div
          class="modal__upload__content min-height-230 d-flex flex-column justify-content-between py-4 px-5"
        >
          <form @submit.prevent="createUser">
            <div class="form-group">
              <label for="userName">Supplier Name</label>
              <input
                type="text"
                v-model="suppliers.name"
                id="userName"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="userName">Address</label>
              <textarea
                v-model="suppliers.address"
                id="userName"
                class="form-control"
                required></textarea>
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="email"
                v-model="suppliers.email"
                id="userName"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="userName">Phone no:</label>
              <input
                type="text"
                v-model="suppliers.phoneNumber"
                id="userName"
                class="form-control"
                required
              />
            </div>

            <div class="form-group">
              <label for="userRole">active</label>
              <select
    v-model="suppliers.active"
    id="userRole"
    class="form-control"
    required
  >
    <option value=true>
      Active
    </option>
    <option value=true>
      Inactive
    </option>

  </select>
  
            </div>
            <button
              type="submit"
              class="btn btn-primary mt-3"
              @click.prevent="submitfun()"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import axios from "axios";
  export default {
    name: "Createuserpopup",
    data() {
      return {
        suppliers: {
          id:"",
          name: "",
          email: "",
          address: "",
          phoneNumber: "",
          active: true,
        },
        roles: ["ADMIN", "User", "Guest"], // Example roles, replace with actual roles if different
      };
    },
    props: {
      currentsupplyid: {
        type: String,
        default: "",
      },
    },
    mounted() {
      this.getsupply(this.currentsupplyid);
    },
    computed: {
    selectedRole() {
      if (this.user && this.roles.includes(this.user.roles[0])) {
        return this.user.roles[0];
      } else {
        return "Guest"; // Set default value to "Guest" if user.roles[0] is not valid
      }
    }
  },
    methods: {
      async getsupply(supplyid) {
        await axios
          .get(
            `https://dmsdev.datamatica.uk/v1/document/test/getsupplier?id=${supplyid}`
          )
          .then((res) => {
            this.suppliers = res.data;
            console.log(res.data);
          })
          .catch((err) => {
            console.log("failed");
            console.log(err);
          });
      },
      async onclosed() {
        this.$emit("oncloseevent");
      },
      async submitfun() {
        if (this.suppliers.id == "") {
          this.createfun();
        } else {
          this.editfun();
        }
      },
      async editfun() {
        await axios
          .put(
            `https://dmsdev.datamatica.uk/v1/document/test/updatesupplier?id=${this.currentsupplyid}`,
            this.suppliers
          )
          .then((res) => {
            console.log(res.data);
            this.onclosed();
          })
          .catch((err) => {
            console.log("failed");
            console.log(err);
          });
      },
      async createfun() {
        // this.category.active = this.category.active == "true";
        await axios
          .post(
            `https://dmsdev.datamatica.uk/v1/document/test/createsupplier`,
            this.suppliers
          )
          .then((res) => {
            console.log(res.data);
            this.onclosed();
          })
          .catch((err) => {
            console.log("failed");
            console.log(err);
          });
      },
      
    },
  };
  </script>
  <style scoped>
  @import "../../assets/css/style.css";
  .modal__upload__wrapper {
    position: fixed;
    inset: 0;
    background: rgb(0 0 0 / 18%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3000;
  }
  .modal__upload__container {
    width: min(38%, 80%);
    position: fixed;
    background: rgb(255, 255, 255);
    border-radius: 4px;
  }
  .modal__upload__title {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #ffffff;
  }
  .height-230 {
    height: 230px;
  }
  .upload_btn {
    text-align: center;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    color: white !important;
    padding: 0.43rem 0.4rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transition: all 0.3s ease-in-out !important;
  }
  .upload_btn img {
    mix-blend-mode: color-dodge;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  </style>
  