<template>
    <div class="container col-md-12">
        <div class="d-flex justify-content-between mt-3">
      <h2 class="headding">Orders</h2>
    </div>
    <div class="d-flex justify-content-between">
        <div class="form-group col-md-2 p-2">
          <label for="itemcanteen">Canteen:</label>
          <select id="itemcanteen" v-model="searchcanteen" class="maxheightelem col-md-12" >
            <option v-for="canteen in canteenlist" :key="canteen.id" :value="canteen.name">{{ canteen.name }}</option>
          </select>
        </div>
        <div class="form-group col-md-2 p-2">
          <label for="itestatus">Status:</label>
          <select id="itestatus" v-model="searchstatus" class="maxheightelem col-md-12" >
            <option value="New" >New</option>
            <option value="Received">Received</option>
            <option value="Completed">Completed</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>
        <div class="form-group col-md-2 p-2">
          <label for="searchnos">From Date:</label>
          <input type="date" class="maxheightelem col-md-12" id="searchnos" v-model="searchfromdate" required />
        </div>
        <div class="form-group col-md-2 p-2">
          <label for="searchnos">To Date:</label>
          <input type="date" class="maxheightelem col-md-12" id="searchnos" v-model="searchtodate" required />
        </div>
        <div class="col-md-3 m-auto">
        <button class="buttonclass btn btn-success mr-2 h-50 mt-auto mb-auto col-md-4" type="submit" @click.prevent="getorderlist()">Search</button>
        </div>
        </div>
        <div>
          <div class="col-md-12 mt-3 d-flex flex-row-reverse">
              <button type="button" class="btn btn-primary col-md-2" @click.prevent="createorder('0')">Create Order</button>
          </div>
      </div>
      <div class="containers mt-2">
        <table class="table table-bordered">
  <thead>
    <tr>
      <th>Canteen Name</th>
      <th>Status</th>
      <th>Order Date</th>
      <th>Delivery Date</th>
      <th>Shopkeeper</th>
      <th>Print PDF</th>
      <th>View</th>
      <th>Edit</th>
      <th>Cancel</th>
      <th>Delete</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="order in orderlist" :key="order.id">
      <td>{{order.canteenName}}</td>
      <td>{{order.status}}</td>
      <td>{{formattedDate(order.date)}}</td>
      <td>{{formattedDate(order.deliveryDate)}}</td>
      <td>{{order.shopkeeper}}</td>
      <td> <button type="button" class="btn btn-success" @click="printPdfFile(order.id)">
          Print
        </button></td>
      <td><button type="button" class="btn btn-info" @click="vieworder(order.id)">View</button></td>
      <td><button type="button" v-if ="order.status != 'Completed' && order.status != 'Cancelled'" class="btn btn-primary" @click="createorder(order.id)">Edit</button></td>
      <td><button type="button" v-if ="order.status != 'Completed' && order.status != 'Cancelled'" class="btn btn-warning" @click.prevent="cancelitem(order.id)">Cancel</button></td>
      <td><button type="button" v-if ="order.status != 'Completed' && order.status != 'Cancelled'" class="btn btn-danger" @click.prevent="deleteitem(order.id)">Delete</button></td>
    </tr>

  </tbody>
</table>
</div>
    </div>
  </template>
  
  <script>
    import axios from 'axios'
  export default {
    data() {
      return {
        username: '',
        password: '',
        orderlist:[],
        Itemlist:[],
        canteenlist:[],
        searchtodate:null,
        searchfromdate:null,
        searchstatus:'',
        searchcanteen:''
      };
    },
    mounted() {
    this.getItemlist();
    this.getorderlist();
    this.getcanteene();
  },
    methods: {
      async downloadPdfFile(id) {
  try {
    const response = await axios.get(
      'https://dmsdev.datamatica.uk/v1/document/test/ordertopdf',
      {
        params: {
          id: id
        },
        responseType: 'blob'
      }
    );

    console.log(response);

    const blob = new Blob([response.data], { type: 'application/pdf' });

    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);

    link.download = 'order.pdf';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  } catch (error) {
    console.error('Failed to download PDF file', error);
  }
},
async printPdfFile(id) {
  try {
    const response = await axios.get(
      'https://dmsdev.datamatica.uk/v1/document/test/ordertopdf',
      {
        params: {
          id: id
        },
        responseType: 'blob'
      }
    );

    console.log(response);

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    
    const printWindow = window.open(url);

    if (printWindow) {
      printWindow.addEventListener('load', () => {
        printWindow.focus();
        printWindow.print();
      }, { once: true });
    } else {
      console.error('Failed to open print window');
    }
  } catch (error) {
    console.error('Failed to download PDF file', error);
  }
},
async printPdfFiledirectly(id) {
  try {
    const response = await axios.get(
      'https://dmsdev.datamatica.uk/v1/document/test/ordertopdf',
      {
        params: {
          id: id
        },
        responseType: 'blob'
      }
    );

    console.log(response);

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;

    document.body.appendChild(iframe);

    iframe.onload = () => {
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    };
  } catch (error) {
    console.error('Failed to download PDF file', error);
  }
},
      formattedDate(date) {
        if (!date || date=="" || date==null) return '';
        return date.split('T')[0];
      },
      async getcanteene(){
      await axios
        .get(
          `https://dmsdev.datamatica.uk/v1/document/test/listcanteens`,

        ).then((res) => {

          this.canteenlist = res.data;

        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
      async getItemlist(){
      await axios
        .get(
          `https://dmsdev.datamatica.uk/v1/document/test/getallitems`,

        ).then((res) => {

          this.Itemlist = res.data;

        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    async cancelitem(itemid){
      const userConfirmed = confirm("Are you sure you want to cancel this order?");
      if (userConfirmed) {
      await axios
        .get(
          `https://dmsdev.datamatica.uk/v1/document/test/cancelorder?id=${itemid}`
        )
        .then((res) => {
            this.getorderlist();
          console.log(res.data);
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
      }
    },
      async deleteitem(itemid){
        const userConfirmed = confirm("Are you sure you want to delete this item?");
      if (userConfirmed) {
      await axios
        .delete(
          `https://dmsdev.datamatica.uk/v1/document/test/deleteorder?id=${itemid}`
        )
        .then((res) => {
            this.getorderlist();
          console.log(res.data);
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
      }
      },
      async getorderlist() {
    const params = new URLSearchParams();
    if (this.searchcanteen) {
      params.append('canteenName', this.searchcanteen);
    }
    if (this.searchstatus) {
      params.append('status', this.searchstatus);
    }
    if (this.searchfromdate) {
      params.append('dateFrom', this.searchfromdate);
    }
    if (this.searchtodate) {
      params.append('dateTo', this.searchtodate);
    }

    const url = `https://dmsdev.datamatica.uk/v1/document/test/listorders?${params.toString()}`;

    await axios
      .get(url)
      .then((res) => {
        this.orderlist = res.data;
      })
      .catch((err) => {
        console.log("failed");
        console.log(err);
      });
  },

      async createorder(itemid){
        console.log('OrderCreate',itemid);
        this.$router.push(`/OrderCreate/${itemid}`);
      },
      async vieworder(itemid){
        this.$router.push(`/OrderView/${itemid}`);
      }
    }

  };
  </script>
  <style>
  .maxheightelem{
    height:40px;
  }
 .container {
  border: 2px solid #000; /* Black border */
  border-radius: 10px; /* Curved edges */
  margin-top: 30px;
  min-height: 100%;
}

.headding {
  margin-left: 10px;
}

/* Scoped styling for tables */
.container .table th {
  background-color: #f0f0f0; /* Gray background for header */
}

.container .table-bordered {
  border: none; /* Remove border */
}
  </style>