<template>
  <div class="container-fluid mt-4">
    <!-- Row 1: Statistics -->
    <div class="row">
      <div class="col-md-6 maxheight">
        <div class="card overviewheight">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h5 class="card-title">Stock</h5>
              <div class="mb-2">
                <input
                  v-model="searchQuery"
                  type="text"
                  placeholder="Item Name"
                  @keyup.enter="executeSearch"
                />
              </div>
              <!-- <font-awesome-icon icon="ellipsis-v" class="iconwhite" /> -->
            </div>
            <div class="tablestyle">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="tdmaxwidth">Item Name</th>
                    <th class="tdmaxwidth">Unit</th>
                    <th class="tdmaxwidth">Available stock</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="stock in stocklist" :key="stock.id">
                    <td class="tdmaxwidth">{{ stock.itemName }}</td>
                    <td class="tdmaxwidth">{{ stock.unit }}</td>
                    <td class="tdmaxwidth">{{ stock.itemCount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="card overviewheight">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <h5 class="card-title">Recent Orders</h5>
              <div class="mb-2">
                <input
                  v-model="catsearchQuery"
                  type="text"
                  placeholder="Canteen Name"
                  @keyup.enter="catexecuteSearch"
                />
              </div>
              <!-- <font-awesome-icon icon="ellipsis-v" class="iconwhite" /> -->
            </div>
            <div class="tablestyle">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="tdmaxwidth">Order ID</th>
                    <th class="tdmaxwidth">Canteen Name</th>
                    <th class="tdmaxwidth">Status</th>
                    <th class="tdmaxwidth">Order Date</th>
                    <th class="tdmaxwidth">View</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="order in orderlist" :key="order.id">
                    <td class="tdmaxwidth" :title="order.orderCode">{{ order.orderCode }}</td>
                    <td class="tdmaxwidth" :title="order.canteenName">{{ order.canteenName }}</td>
                    <!-- <td class="tdmaxwidth">{{order.status}}</td> -->
                    <td class="tdmaxwidth">
                      <select
                        id="CanteenName"
                        :disabled="order.status == 'Completed' || order.status == 'Cancelled'"
                        v-model="order.status"
                        @click.prevent="currentststus(order.status)"
                        @change.prevent="changevaluefun(order)"
                        required
                      >
                        <option value="New">New</option>
                        <option value="Received">Received</option>
                        <option value="Completed">Completed</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>
                    </td>
                    <td class="tdmaxwidth">{{ formattedDate(order.date) }}</td>
                    <td class="tdmaxwidth">
                      <font-awesome-icon
                        icon="fa-eye"
                        class="blackicon viewicon"
                        @click.prevent="vieworder(order.id)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</template>

<script>
import axios from "axios";
export default {
  // Component logic here
  data() {
    return {
      searchQuery: "",
      stocklist: [],
      orderlist: [],
      currentstatus: "",
      catsearchQuery:""
    };
  },
  mounted() {
    this.getstocklist();
    this.getorderlist();
  },
  computed: {
    filteredItems() {
      return this.stocklist.filter((item) =>
        item.itemName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    async executeSearch() {
      await this.getstocklist();
      if (this.searchQuery != "") {
        this.stocklist = this.stocklist.filter((item) =>
          item.itemName.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
    },
    async catexecuteSearch(){
      await this.getorderlist();
      if (this.catsearchQuery != "") {
        this.orderlist = this.orderlist.filter((item) =>
          item.canteenName.toLowerCase().includes(this.catsearchQuery.toLowerCase())
        );
      }
    },
    async vieworder(orderid) {
      this.$router.push(`/OrderView/${orderid}`);
    },
    formattedDate(date) {
      if (!date || date == "" || date == null) return "";
      return date.split("T")[0];
    },
    async currentststus(currentstatus) {
      this.currentstatus = currentstatus;
    },
    async changevaluefun(order) {
      if (this.currentstatus == "Completed") {
        alert(`You cannot change completed status`);
        order.status = this.currentstatus;
      } else {
        const userConfirmed = confirm(
          "Are you sure you want to change the status?"
        );
        if (userConfirmed) {
          this.changestatus(order);
        } else {
          alert("status not changed");
          order.status = this.currentstatus;
        }
      }
    },
    async getstocklist() {
      await axios
        .get("https://dmsdev.datamatica.uk/v1/document/test/getallitems")
        .then((res) => {
          this.stocklist = res.data;
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
    async changestatus(order) {
      await axios
        .get(
          `https://dmsdev.datamatica.uk/v1/document/test/updateorderstatus?id=${order.id}&newStatus=${order.status}`
        )
        .then((res) => {
          alert(`status changed to ${order.status}`);
        })
        .catch((err) => {
          alert("Failed to change status");
          console.log(err);
        });
    },
    async getorderlist() {
      await axios
        .get("https://dmsdev.datamatica.uk/v1/document/test/listorders")
        .then((res) => {
          this.orderlist = res.data;
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.tdmaxwidth {
  max-width: 200px;
  overflow: hidden;
}
.tablestyle {
  width: 575px;
  overflow: auto;
  height: 500px;
}
.overviewheight {
  height: 300px;
}
.card-body {
  background-color: aliceblue;
}
.roudeddesign {
  width: 80px;
  height: 80px;
  border-radius: 30%;
}
.contentsadded {
  margin-top: 44px;
  color: #969090;
  border-bottom: solid;
}
.spaceview {
  margin-left: 25px;
}
.squiredesign {
  width: 100px;
  height: 100px;
  background-color: white;
  border: solid;
  border-width: 1px;
  border-radius: 7px;
}
.tbcontents {
  width: 578px;
  overflow: auto;
  display: block;
}
.viewicon {
  color: blue;
  cursor: pointer;
}
.viewicon :hover {
  color: rgb(233, 7, 75);
  cursor: pointer;
}
.maxheight{
  height:600px;
}
/* Add custom styles if needed */
</style>
