<template>
    <div class="login-form">
      <h2>Login</h2>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="username">Username:</label>
          <input type="text" id="username" v-model="username" required>
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="password" required>
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import store from "../../store/index";
  export default {
    data() {
      return {
        username: '',
        password: ''
      };
    },
    methods: {
      // async login() {
      //   if(
      //     this.username == "admin" &&
      //     this.password == "password")

      //   {
          
      //      store.dispatch("setloggedIn", "true");
      //      store.dispatch("setUserName", "admin");
      //     this.$router.push(`/home`);
      //   } else {
      //     alert('Login failed. Please check your credentials.');
      //   }

      // }
      async login() {
        try {
        const response = await axios.post('https://dmsdev.datamatica.uk/v1/document/test/login',
        
        {
          usernameOrEmail: this.username,
          password: this.password
        },
      );

        if (response.status === 200) {
           store.dispatch("setloggedIn", "true");
           store.dispatch("setUserName", response.data.fullName);
           store.dispatch("setUserid", response.data.id);
          this.$router.push(`/home`);
        } else {
          alert('Login failed. Please check your credentials.');
        }
      } catch (error) {
        console.error('Login error:', error);
        alert('Login failed. Please check your credentials.');
      }
      }
    }
  };
  </script>
  
  <style>
  .login-form {
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="password"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  </style>
  