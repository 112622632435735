<template>
    <div class="container col-md-12">
        <div class="d-flex justify-content-between mt-3">
      <h2 class="headding">Items</h2>
      
    </div>
    <div class="d-flex justify-content-between">
        <div class="form-group col-md-4 p-2">
          <label for="searchitem">Item Name:</label>
          <input type="text" id="searchitem" v-model="searchitem" class="maxheightelem col-md-12" >
          
        </div>

  
        <div class="col-md-3 m-auto">
        <button class="buttonclass btn btn-success mr-2 h-50 mt-auto mb-auto col-md-4" type="submit" @click.prevent="getItemlist()">Search</button>
        </div>
        <div class="col-md-4 m-auto">
        </div>
        </div>
        <div class="d-flex justify-content-between mt-3 col-md-12 flex-row-reverse">
      <button type="button" class="btn btn-primary ml-3 col-md-2 btnclass" @click.prevent="createitem('0')">Create item</button>
    </div>
      <div class="containers mt-1">
        <table class="table table-bordered">
  <thead>
    <tr>
      <th>Items Name</th>
      <th>Available Stock</th>
      <th>Catagory</th>
      <th>Unit Of Mesurement</th>
      <th>Rate</th>
      <th>Alternative Unit</th>
      <th>Conversion Rate</th>
      <th>Download QR Code</th>
      <th>Edit</th>
      <th>Delete</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="items in Itemlist" :key="items.id">
      <td>{{items.itemName}}</td>
      <td>{{items.itemCount}}</td>
      <td>{{items.catagory}}</td>
      <td>{{items.unit}}</td>
      <td>{{items.rate}}</td>
      <td>{{items.alternativeUnit}}</td>
      <td>{{items.conversionRate}}</td>
      <td> <button type="button" class="btn btn-info" @click="downloadQrCode(items.itemName)">
          Download
        </button></td>
      <td><button type="button" class="btn btn-primary" @click="createitem(items.id)">Edit</button></td>
      <td><button type="button" class="btn btn-danger" @click.prevent="deleteitem(items.id)">Delete</button></td>
    </tr>
    
  </tbody>
</table>
</div>
    </div>
  </template>
  
  <script>
    import axios from 'axios'
  export default {
    data() {
      return {
        searchitem:"",
        username: '',
        password: '',
        Itemlist:[]
      };
    },
    mounted() {
    this.getItemlist();
  },
    methods: {
      async downloadQrCode(itemname) {
  try {
    const formData = new FormData();
    formData.append('Heading', itemname);
    formData.append('ItemName', itemname);
    formData.append('QrFor', 'StockWithoutSetCount');

    const response = await axios.post(
      'https://dmsdev.datamatica.uk/v1/document/test/createqr',
      formData,
      { responseType: 'blob' }
    );

    console.log(response);
    const blob = new Blob([response.data], { type: 'image/jpeg' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${itemname}-qrcode.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Failed to download QR code', error);
  }
},
      async deleteitem(itemid){
        const userConfirmed = confirm("Are you sure you want to delete this item?");
      if (userConfirmed) {
      await axios
        .delete(
          `https://dmsdev.datamatica.uk/v1/document/test/deleteitem?itemId=${itemid}`
        )
        .then((res) => {
            this.getItemlist();
          console.log(res.data);
        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
      }
      },
      async getItemlist(){
      await axios
        .get(
          `https://dmsdev.datamatica.uk/v1/document/test/getallitems?key=${this.searchitem}`,

        ).then((res) => {

          this.Itemlist = res.data;

        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
      async createitem(itemid){
        console.log('createitem',itemid);
        this.$router.push(`/CreateItem/${itemid}`);
      }
    }

  };
  </script>
  <style>
  .btnclass{
    float: right;
  }
 .container {
  border: 2px solid #000; /* Black border */
  border-radius: 10px; /* Curved edges */
  margin-top: 30px;
  min-height: 600px !important;
}
.containers {
    overflow: auto;
    max-height: 450px;
}
.headding {
  margin-left: 10px;
}

/* Scoped styling for tables */
.container .table th {
  background-color: #f0f0f0; /* Gray background for header */
}

.container .table-bordered {
  border: none; /* Remove border */
}
  </style>