/* eslint-disable */
// import {tokenAlive} from "@/Shared/jwtHelper";
const state = () => ({
    loggedIn: localStorage.getItem('loggedIn'),
    username: localStorage.getItem('username'),
    userid: localStorage.getItem('userid'),
});
const getters = {

      getifloggedIn(state: { loggedIn: { token: any;} }){
        return state.loggedIn;
      },
      getusername(state: { username: { token: any;} }){
        return state.username;
      },
      getuserid(state: { userid: { token: any;} }){
        return state.userid;
      },
};

const actions = {

     async setloggedIn({commit}: any, data: any) {
          commit("setloggedIn", data);
                    },
    async setUserName({commit}: any, data: any) {
          commit("setUserName", data);
                    },
    async setUserid({commit}: any, data: any) {
          commit("setUserid", data);
                    },
};

const mutations = {

    setloggedIn(state: { loggedIn: any }, usersetloggedIn: any){
        state.loggedIn=usersetloggedIn;
        localStorage.setItem("loggedIn", usersetloggedIn);
      },
      setUserName(state: { username: any }, usersetName: any){
        state.username= usersetName;
        localStorage.setItem("username", usersetName);
      },
      setUserid(state: { userid: any }, usersetID: any){
        state.userid= usersetID;
        localStorage.setItem("userid", usersetID);
      }
};

export default {
    state,
    getters,
    actions,
    mutations
}