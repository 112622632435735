<template>
  <div class="container col-md-12">
      <div class="row justify-content-between">
          <h2 class="heading col-md-3 mt-3">All Stocks</h2>

      </div>
      <div class="d-flex justify-content-between">
        <div class="form-group col-md-2 p-2">
          <label for="itemcanteen">Item Name:</label>
          <select id="itemcanteen" v-model="searchitemname" class="maxheightelem col-md-12" >
            <option v-for="item in Itemlist" :key="item.id" :value="item.itemName">{{ item.itemName }}</option>
          </select>
        </div>
        <div class="form-group col-md-2 p-2">
          <label for="searchcategory">Category:</label>
          <select id="searchcategory" v-model="searchcategory" class="maxheightelem col-md-12" >
            <option v-for="category in categorylist" :key="category.id" :value="category.name">{{ category.name }}</option>
          </select>
        </div>
        <div class="form-group col-md-2 p-2">
          <label for="searchfromdate">Purchase Date From:</label>
          <input type="date" class="maxheightelem col-md-12" id="searchnos" v-model="searchfromdate" required />
        </div>
        <div class="form-group col-md-2 p-2">
          <label for="searchtodate">Purchase Date To:</label>
          <input type="date" class="maxheightelem col-md-12" id="searchnos" v-model="searchtodate" required />
        </div>
        <div class="col-md-3 m-auto">
        <button class="buttonclass btn btn-success mr-2 h-50 mt-auto mb-auto col-md-4" type="submit" @click.prevent="getStocklist()">Search</button>
        </div>
        </div>
        <div>
          <div class="col-md-12 mt-3 d-flex flex-row-reverse">
              <button type="button" class="btn btn-primary col-md-2" @click.prevent="createStock('0')">Add Stock</button>
          </div>
      </div>
      <div class="containers mt-2">
          <table class="table table-bordered tablestyle">
              <thead>
                  <tr>
                      <th>Items Name</th>
                      <th>Quantity</th>
                      <th>Unit</th>
                      <th>Alternative Quantity</th>
                      <th>Alternative Unit</th>
                      <th>Convertion Rate</th>
                      <th>Category</th>
                      <th>Supplier</th>
                      <th>Rate</th>
                      <th>Expiry Date</th>
                      <th>Purchase Date</th>
                      <th>Edit</th>
                      <th>Delete</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="item in Stocklist" :key="item.id">
                      <td>{{ item.itemName }}</td>
                      <td>{{ item.quantity }}</td>
                      <td>{{ item.unit }}</td>
                      <td>{{ item.alternativeQuantity }}</td>
                      <td>{{ item.alternativeUnit }}</td>
                      <td>{{ item.convertionRate }}</td>
                      <td>{{ item.catagory }}</td>
                      <td>{{ item.supplier }}</td>
                      <td>{{ item.ratePerUnit }}</td>
                      <td>{{ formatDate(item.expireDate) }}</td>
                      <td>{{ formatDate(item.purchaseDate) }}</td>
                      <td>
                          <button type="button" class="btn btn-primary" @click.prevent="createStock(item.id)">Edit</button>
                      </td>
                      <td>
                          <button type="button" class="btn btn-danger" @click.prevent="deletefun(item.id)">Delete</button>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
      return {
        searchitemname:'',
        searchcategory:'',
        searchfromdate:'',
        searchtodate:'',
        Stocklist: [],
        Itemlist:[],
        categorylist:[],
      };
  },
  mounted() {
      this.getItemlist();
      this.getcategorylist();
      this.getStocklist();
  },
  methods: {
    async getcategorylist(){
    await axios
      .get(
        `https://dmsdev.datamatica.uk/v1/document/test/listcategories`,

      ).then((res) => {

        this.categorylist = res.data;

      })
      .catch((err) => {
        console.log("failed");
        console.log(err);
      });
  },
    async getItemlist(){
      await axios
        .get(
          `https://dmsdev.datamatica.uk/v1/document/test/getallitems`,

        ).then((res) => {

          this.Itemlist = res.data;

        })
        .catch((err) => {
          console.log("failed");
          console.log(err);
        });
    },
       formatDate(dateString) {
          if (!dateString) {
              return null;
          }
          const datePart = dateString.split('T')[0];
          return datePart;
      },
      async deletefun(stockid) {
          const userConfirmed = confirm("Are you sure you want to delete this stock?");
          if (userConfirmed) {
              await axios
                  .delete(`https://dmsdev.datamatica.uk/v1/document/test/deletestock?id=${stockid}`)
                  .then((res) => {
                      this.getStocklist();
                      console.log(res.data);
                  })
                  .catch((err) => {
                      console.log("failed");
                      console.log(err);
                  });
          }
      },
      async getStocklist() {
        const params = new URLSearchParams();
    if (this.searchitemname) {
      params.append('itemName', this.searchitemname);
    }
    if (this.searchcategory) {
      params.append('category', this.searchcategory);
    }
    if (this.searchfromdate) {
      params.append('purchaseDateFrom', this.searchfromdate);
    }
    if (this.searchtodate) {
      params.append('purchaseDateTo', this.searchtodate);
    }
          await axios
              .get(`https://dmsdev.datamatica.uk/v1/document/test/liststocks?${params.toString()}`)
              .then((res) => {
                  this.Stocklist = res.data;
              })
              .catch((err) => {
                  console.log("failed");
                  console.log(err);
              });
      },
      async createStock(stockid) {
          console.log('createitem');
          this.$router.push(`/Createstock/${stockid}`);
      }
  }
};
</script>

  <style>
  .iconedit{
    color:blue;
    font-size:24px;
  }
  .container{
    border: 2px solid #000; /* Black border */
  border-radius: 10px; /* Curved edges */
  border: 2px solid #000;
    border-radius: 10px;
    margin-top: 30px;
    min-height: 600px !important;
  }

.containers {
    overflow: auto;
    max-height: 450px;
}
  .headding{
    margin-left: 10px;
  }
  .table th {
      background-color: #f0f0f0; /* Gray background for header */
    }
    .table-bordered {
      border: none; /* Remove border */
    }
    .search-box {
  display: inline-block;
  border-radius: 10px;
  padding: 5px 10px;
}

.search-input {
  border: none;
  outline: none;
  background: none;
  width: 200px;
  padding: 5px;
  font-size: 16px;
}

  </style>